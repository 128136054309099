<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.xapi_content">
			<template #title>Xapi Contents</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'XapiContentsNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>
			<v-data-table :headers="headers" :items="xapi_contents" item-key="id" :search="search" @click:row="editItem" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";
import models from "../../models";

export default {
	name: "XapiContentsIndex",
	computed: {
		xapi_contents: sync('xapi_contents/xapi_contents')
	},
	data: () => ({
		search: '',
		headers: [
			{
				text: 'Name',
				align: 'start',
				sortable: true,
				value: 'name',
			},
			{
				text: 'Brand',
				align: 'start',
				sortable: true,
				value: 'brand_name',
			},
			{
				text: 'Description',
				align: 'start',
				sortable: true,
				value: 'description',
			},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.getXapiContents();
		},
		editItem(data) {
			this.$router.push({name: 'XapiContentsEdit', params: {id: data.id}});
		},
		getXapiContents() {
			this.$store.set('xapi_contents/getXapi_contents!', null)
		},
		filterOnlyCapsText(value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>